<template>
  <section class="providers container">
    <LoadingSpinner v-if="loading" />

    <template v-else>
      <h3>{{ heading }}</h3>
      <div class="tiles">
        <logo-container
          v-for="(item, index) in content.items"
          :key="index"
          class="tile"
          :logo-url="item.fields.logo.fields.file.url"
          title="title"
          @click="navigate(index)" />
      </div>
    </template>
  </section>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'
import LogoContainer from '@/components/common/LogoContainer'

import {
  mapActions, mapState
} from 'vuex'
import { RoutePath } from '@/router/router-constants'

export default {
  components: {
    LoadingSpinner,
    LogoContainer
  },
  props: {
    heading: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('ServiceProviders', [
      'loading',
      'exception',
      'content'
    ])
  },
  mounted () {
    this.loadContent()
  },
  methods: {
    ...mapActions('ServiceProviders', ['loadContent']),
    navigate (index) {
      this.$router.push({ path: `${RoutePath.public.ServiceProviders}#service_provider_${index}` })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.providers {
  padding: 64px 120px;
  transition: all 0.5s ease-in-out;

  @media only screen and (max-width: $breakpoint-md) {
    padding: 32px 58px;
    transition: all 0.5s ease-in-out;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    padding: 32px 26px;
    transition: all 0.5s ease-in-out;
  }

  .tiles {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 0 -22px;

    @media only screen and (max-width: $breakpoint-sm) {
      margin: 0 -12px;
    }

    .tile {
      height: 96px;
      width: 96px;
      margin: 22px;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
      }

      &:active {
        box-shadow: 0 0 20px $core-teal-800;
      }

      @media only screen and (max-width: $breakpoint-sm) {
        height: 61px;
        width: 61px;
        margin: 12px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
</style>
