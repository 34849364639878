<template>
  <div
    class="icons-container"
    :class="{'grey-container': isGreyBackground}">
    <div class="row container">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="col-md icon text-center">
        <div class="row justify-content-center icon-image-row">
          <div class="icon-image-container">
            <img
              :src="item.icon"
              alt="icon">
          </div>
        </div>
        <div class="row justify-content-center icon-link-row">
          <Link
            :external="item.external"
            :link="item.link"
            :text="item.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Link from '@/components/form-components/Link'
export default {
  components: {
    Link
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    isGreyBackground: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.icons-container {
  padding: 64px 0;
}

.grey-container {
  background-color: $grey-100;

  @media(max-width: $breakpoint-md) {
    background-color: $core-white;
  }
}

.icon {
  pointer-events: none;

  &:hover &-image-container {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
  }

  &-image-container {
    background-color: $core-white;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.13);
    transition: all 0.2s ease-in-out;

    img {
      position: relative;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }

  &-link-row {
    padding: 30px 0;

    > * {
      pointer-events: auto;
    }
  }
}

</style>
