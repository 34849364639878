<template>
  <div>
    <template v-if="loading">
      <p>Loading ...</p>
    </template>

    <template v-else>
      <Hero
        :background-image="require('@/assets/image/hero-background-image.png')"
        :heading="content.hero.heading"
        :sub-heading="content.hero.subHeading">
        <MainButton
          :label="content.hero.buttonText"
          @click="goToAddressSearch" />
      </Hero>

      <ServiceProviderIcons :heading="content.serviceProviders.heading" />

      <AddressSearch />

      <Icons
        :is-grey-background="content.icons.isGreyBackground"
        :items="content.icons.items" />
    </template>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import Hero from '@/components/landing-page/Hero'
import ServiceProviderIcons from '@/components/landing-page/ServiceProviderIcons'
import Icons from '@/components/icons/Icons'
import AddressSearch from '@/components/address-search/AddressSearch'
import MainButton from '@/components/MainButton'
import { RouteName } from '@/router/router-constants'

export default {
  components: {
    Hero,
    ServiceProviderIcons,
    Icons,
    AddressSearch,
    MainButton
  },
  computed: {
    ...mapState('LandingPage', [
      'loading',
      'exception',
      'content'
    ])
  },
  created () {
    this.loadContent()
  },
  methods: {
    ...mapActions('LandingPage', ['loadContent']),
    goToAddressSearch () {
      this.$router.push({ name: RouteName.public.AddressSearch })
    }
  }
}
</script>
