<template>
  <a
    v-if="external"
    class="link"
    :href="link">
    {{ text }}
  </a>
  <router-link
    v-else
    class="link"
    :to="link">
    {{ text }}
  </router-link>
</template>

<script>
export default {
  props: {
    external: {
      type: Boolean
    },
    link: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>
